<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <pz-direction
        v-model="formulario2.direccion"
        ask-postal-code
        is-only-read
      />
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_fijo"
            :label="$t('forms_flujo.form2_persona.UY.phone')"
            :error="errors.get('telefono_fijo')"
          >
            <el-input
              v-model="formulario2.telefono_fijo"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.phone')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.form2_persona.UY.email')"
            :error="errors.get('email')"
          >
            <el-input
              v-model="formulario2.email"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.email')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import { type} from "@/commons/utils/Rules";
    import {
        getDepartamentos
    } from "@/routes/api/resources";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import {getFromLocal} from "@/commons/utils/storage";

    export default {
        name: 'PzPersonFormExtFenoreste',
        components: {PzFormFlujo,PzDirection},
        mixins: [baseform2],
        data() {
            return {
                provincias: [],
                formulario2: {
                    direccion: {},
                    provincia: '',
                    telefono_fijo: '',
                    email: '',
                    url_trackeo: '',
                    ref_empresa: 1,
                    tipo_documento: 1
                }
                ,
                disable: true,
                rules_formulario2:
                    {
                        email: [
                            type({field: '', type: 'email'})
                        ]
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },
        created: function () {
        },
        beforeMount() {
            var dep = getDepartamentos().then((res) => {
                this.provincias = res.data;
            }).catch(() => {
                this.provincias = [];
            });

            Promise.all([ dep]).then(() => {

            }).finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
        },
        methods: {
            isReadOnly(data){
                let data_client = getFromLocal('data_client');
                if(data_client)
                    return data_client[data]?true:false
                else
                    return false;
            },
        },
    }
</script>

